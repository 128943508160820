import React, {useState} from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent,Stack, Divider, Checkbox,TextField, Typography,Tooltip } from "@mui/material";
import { FaYoutube } from "react-icons/fa";
import { useSettings } from 'src/hooks/use-settings';
import {addWidget, editContent} from "src/redux/actions/Content";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import toast from "react-hot-toast";


const DialogBoxYoutubePlaylist = ({ object, isNew, isOpen, onClose }) => {
    const settings = useSettings();
    const dispatch = useDispatch();
    const { user, auth, loggedin, users } = useSelector((state) => state.User);
    const { currentFolder } = useSelector(
      (state) => ({
        currentFolder: state.FileFolder.currentFolder,
      }),
      shallowEqual
    );

    //-------------------- States --------------------
    const [savedYtConfig, setSavedYtConfig] = useState( object ? {name: object.name, url: object?.widget?.url, caption: object?.widget?.caption, shuffle:object?.widget?.shuffle} 
                                                               : {name:"", url:"", caption:true, shuffle: false});
    const [ytConfig, setYtConfig] = useState( object ? {name: object.name, url: object?.widget?.url, caption: object?.widget?.caption, shuffle:object?.widget?.shuffle} 
                                                               : {name:"", url:"", caption:true, shuffle: false});
    const [isSaved, setIsSaved] = useState(isNew ? false : true);
    const [savedId, setSavedId] = useState("");
  
    //-------------------- Functions --------------------
    const convertLink = (link) => {
      const playlistRegex = /[?&]list=([a-zA-Z0-9_-]+)/;
      const match = link.match(playlistRegex);
  
      if (match) {
        const playlistId = match[1];
        console.log("playlistId: ", playlistId);
        return `https://www.youtube.com/playlist?list=${playlistId}`;
      } else {
        toast.error("Invalid link given");
        return "error";
      }
    };

    const compareConfigs = (config1, config2) => {
      return (
        config1.name === config2.name &&
        config1.url === config2.url &&
        config1.caption === config2.caption &&
        config1.shuffle === config2.shuffle
      );
    };

    const canSave = (config1, config2) => {
      if (isSaved){
        if (compareConfigs(config1, config2)){
          return false;
        }
        else{
          if (!ytConfig.name || !ytConfig.url){
            return false;
          }
          else{
            return true;
          }
        }
      }
      if (!isSaved){
        if (!ytConfig.name || !ytConfig.url){
          return false;
        }
        else{
          return true;
        }
      }
    }

    const handleChangeYtConfig = (key, value) => {
        setYtConfig({...ytConfig, [key]: value});
    }

    const handleSave = async () =>{

      const convertedLink = convertLink(ytConfig.url);
       if (convertedLink === "error") {
        return;
       }

      let action = addWidget({
        name: ytConfig.name,
        type: "widget", 
        widgetCat: "yt_playlist", 
        url: convertedLink, 
        caption: ytConfig.caption,
        shuffle: ytConfig.shuffle,
        user: user.isMember ? user?.user._id : user._id,
        folderId: currentFolder,
       });

      if (isSaved && savedId) {
        console.log(">>>> Edit widget6+ ", savedId);
        action = editContent({
          object:{
            name: ytConfig.name,
            type: "widget", 
            widgetCat: "yt_playlist", 
            url: convertedLink, 
            caption: ytConfig.caption,
            shuffle: ytConfig.shuffle,
            user: user.isMember ? user?.user._id : user._id,
            folderId: currentFolder,
          },
          id: savedId
        })
      }

      const content = await dispatch(action);
      console.log("saved content yt playlist: ", content);
      console.log("saved content yt playlist id: ", content.id);
      if (content && content?.id){
        setSavedId(content.id);
      }
      setSavedYtConfig(ytConfig);
      setIsSaved(true);
    }


    //-------------------- use Effects --------------------
    React.useEffect(()=>{
      if (isOpen && isNew){
        setYtConfig({name:"", url:"", caption:true, shuffle: false});
        setSavedId("");
        setIsSaved(false);
      }
    },[isOpen])

    React.useEffect(()=>{
      if (isOpen && object){
        setYtConfig({name: object.name, url: object?.widget?.url, caption: object?.widget?.caption, shuffle:object?.widget?.shuffle});
        setSavedYtConfig({name: object.name, url: object?.widget?.url, caption: object?.widget?.caption, shuffle:object?.widget?.shuffle});
        setIsSaved(true);
        setSavedId(object.id);
      }
    },[isOpen,object])

  return (
    <Dialog
    fullWidth
    open={isOpen}
    onClose={() => onClose(false)}
    >     
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" backgroundColor={"#1c2536"} width={"100%"} sx={{padding:"15px"}}>
        <FaYoutube size={80} sx={{ fontSize: 80, color:settings.paletteMode=="light" ? "#4c525d" : "white"  }}/>
        <Typography  sx={{fontWeight:"bold", fontSize:"30px"}}>Youtube Playlist </Typography>
    </Box>
    <div style={{
            width: "100%",
            backgroundColor: "#e0e0e0",
            height: "1px",
        }}></div> 
    <DialogContent >

        <Stack direction="column" sx={{gap:"15px", justifyContent:"center"}}>
        <TextField
            label="Name *"
            variant="outlined"
            value={ytConfig.name}
            onChange={(e)=>handleChangeYtConfig("name",e.target.value)}
            fullWidth
        />
        <TextField
            label="URL *"
            variant="outlined"
            value={ytConfig.url}
            onChange={(e)=>handleChangeYtConfig("url",e.target.value)}
            fullWidth
        />

            <Stack direction="row" sx={{alignItems:"center", marginLeft:"2px", marginTop:"-5px"}}>
                <Typography>Caption </Typography>
                <Checkbox  checked={ytConfig.caption} onChange={()=>setYtConfig(prevConfig=>({...prevConfig, caption: !prevConfig.caption}))}/>
            </Stack>
            <Stack direction="row" sx={{alignItems:"center", marginLeft:"2px", marginTop:"-10px", gap:"7px"}}>
                <Typography>Shuffle </Typography>
                <Checkbox  checked={ytConfig.shuffle} onChange={()=>setYtConfig(prevConfig=>({...prevConfig, shuffle: !prevConfig.shuffle}))}/>
            </Stack>

        </Stack>
    </DialogContent>
    <Divider sx={{ width: '100%' }} />
    <DialogActions sx={{ backgroundColor: "#1A2432", width: "100%", padding:"8px",paddingTop:"7px", paddingBottom:"7px", 
        display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}>

          <Tooltip title="Wall must be saved to preview" arrow>
            <span> 
             <Button disabled={!isSaved} onClick={()=>{window.open(ytConfig.url, '_blank')}} variant="contained">Preview</Button>
            </span>
          </Tooltip>

            <Stack direction="row" sx={{alignItems:"center", gap:"10px"}}>
                <Button onClick={() => onClose(false)} variant="outlined">Close</Button>
                <Tooltip title="Name and URL required" arrow>
                  <span> 
                   <Button disabled={!canSave(ytConfig, savedYtConfig)} variant="contained" onClick={()=>handleSave()}>Save</Button>
                  </span>
                </Tooltip>
            </Stack>
  
    </DialogActions>
    </Dialog>
  )
}

export default DialogBoxYoutubePlaylist