import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

const TwitchLivePreview = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const hostname = new URL(baseUrl).hostname;

  const [searchParams] = useSearchParams();
  const channel = searchParams.get('channel');
  const showChatParam = searchParams.get('chat');
  const themeParam = searchParams.get('theme');

  const [showChat, setShowChat] = useState(showChatParam === 'true');
  const isDarkTheme = themeParam === 'dark';

  return (
    <div style={{ height: '100vh', width: '100vw', display: 'flex',
      backgroundColor: isDarkTheme ? '#121212' : '#ffffff',
      color: isDarkTheme ? '#ffffff' : '#000000', 
     }}>
      <Grid container style={{ height: '100%', flex: 1 }}>
        {/* Kolumna na stream */}
        <Grid
          item
          xs={showChat ? 8 : 12}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}
        >
          <iframe
            src={`https://player.twitch.tv/?channel=${channel}&parent=${hostname}`}
            height="100%"
            width="100%"
            allowFullScreen
            frameBorder="0"
            title="Twitch Live Stream"
          ></iframe>
        </Grid>

        {showChat && (
          <Grid
            item
            xs={4}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}
          >
            <iframe
              src={`https://www.twitch.tv/embed/${channel}/chat?parent=${hostname}&darkpopout=${isDarkTheme}`}
              height="100%"
              width="100%"
              frameBorder="0"
              title="Twitch Chat"
            ></iframe>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default TwitchLivePreview;
