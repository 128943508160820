import React, {useState} from 'react'
import { Box, Button, Dialog, DialogActions, Stack, DialogContent, Divider, Select, MenuItem, FormControl, InputLabel,TextField, Typography, Checkbox, Tooltip} from "@mui/material";
import { FaTwitch } from "react-icons/fa";
import { useSettings } from 'src/hooks/use-settings';
import {addWidget, editContent} from "src/redux/actions/Content";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

const Themes = {
    LIGHT: "Light Theme",
    DARK: "Dark Theme",
}

const DialogBoxTwitchPlaylist = ({ object, isNew, isOpen, onClose }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;


    const settings = useSettings();
    const dispatch = useDispatch();
    const { user, auth, loggedin, users } = useSelector((state) => state.User);
    const { currentFolder } = useSelector(
        (state) => ({
          currentFolder: state.FileFolder.currentFolder,
        }),
        shallowEqual
    );
    // ---------- States ----------
    const [savedTwitchConfig, setSavedTwitchConfig] = useState( object ? {name: object.name, url: object?.widget?.url, theme: object?.widget?.theme, chat:object?.widget?.chat} 
                                                                       : {name:"", url:"", theme:Themes.LIGHT, chat: false});
    const [twitchConfig, setTwitchConfig] = useState( object ? {name: object.name, url: object?.widget?.url, theme: object?.widget?.theme, chat:object?.widget?.chat} 
                                                             : {name:"", url:"", theme:Themes.LIGHT, chat: false});
    const [isSaved, setIsSaved] = useState(isNew ? false : true);
    const [savedId, setSavedId] = useState("");
    const [channel, setChannel] = useState("");

    // ---------- Functions ----------
    const hangleChangeTwitchConfig = (key, value) => {
        setTwitchConfig({...twitchConfig, [key]: value});
    }

    const compareConfigs = (config1, config2) => {
        return (
          config1.name === config2.name &&
          config1.url === config2.url &&
          config1.theme === config2.theme &&
          config1.chat === config2.chat
        );
      };

      const canSave = (config1, config2) => {
        if (isSaved){
          if (compareConfigs(config1, config2)){
            return false;
          }
          else{
            if (!twitchConfig.name || !twitchConfig.url){
              return false;
            }
            else{
              return true;
            }
          }
        }
        if (!isSaved){
          if (!twitchConfig.name || !twitchConfig.url){
            return false;
          }
          else{
            return true;
          }
        }
    }  

    const handleSave = async () =>{
        console.log("converted widget: ", {
          name: twitchConfig.name,
          type: "widget", 
          widgetCat: "twitch", 
          url: twitchConfig.url, 
          theme: twitchConfig.theme,
          chat: twitchConfig.chat,
          user: user.isMember ? user?.user._id : user._id
         });
 
  
        let action = addWidget({
            name: twitchConfig.name,
            type: "widget", 
            widgetCat: "twitch", 
            url: twitchConfig.url, 
            theme: twitchConfig.theme,
            chat: twitchConfig.chat,
            user: user.isMember ? user?.user._id : user._id,
            folderId: currentFolder,
        });
  
        if (isSaved && savedId) {
          console.log(">>>> Edit widget");
          action = editContent({
            object:{
                name: twitchConfig.name,
                type: "widget", 
                widgetCat: "twitch", 
                url: twitchConfig.url, 
                theme: twitchConfig.theme,
                chat: twitchConfig.chat,
                user: user.isMember ? user?.user._id : user._id,
                folderId: currentFolder,
            },
            id: savedId
          })
        }
  
        const content = await dispatch(action);
        if (content && content?.id){
          setSavedId(content.id);
        }
        setSavedTwitchConfig(twitchConfig);
        setIsSaved(true);
      }

    //-------------------- use Effects --------------------
    React.useEffect(()=>{
        if (isOpen && isNew){
          setTwitchConfig({name:"", url:"", theme:Themes.LIGHT, chat: false});
          setSavedId("");
          setIsSaved(false);
        }
      },[isOpen])

      React.useEffect(()=>{
        if (isOpen && object){
          setTwitchConfig({name: object.name, url: object?.widget?.url, theme: object?.widget?.theme, chat:object?.widget?.chat});
          setSavedTwitchConfig({name: object.name, url: object?.widget?.url, theme: object?.widget?.theme, chat:object?.widget?.chat});
          setIsSaved(true);
          setSavedId(object.id);
        }
      },[isOpen, object])

        React.useEffect(()=>{
            if (twitchConfig.url){
              const parts = twitchConfig.url.split('/');
              setChannel(parts[parts.length - 1]);
            }
        },[twitchConfig])

        
  return (
    <Dialog
    fullWidth
        open={isOpen}
    onClose={() => onClose(false)}
    >    
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" backgroundColor={"#1c2536"} width={"100%"} sx={{padding:"15px"}}>
        <FaTwitch size={80} sx={{ fontSize: 80, color:settings.paletteMode=="light" ? "#4c525d" : "white"  }}/>
        <Typography  sx={{fontWeight:"bold", fontSize:"30px"}}>Twitch </Typography>
    </Box>
    <div style={{
            width: "100%",
            backgroundColor: "#e0e0e0",
            height: "1px",
        }}></div> 
    <DialogContent >

        <Stack direction="column" sx={{gap:"15px", justifyContent:"center"}}>
        <TextField
            label="Name *"
            variant="outlined"
            value={twitchConfig.name}
            onChange={(e)=>hangleChangeTwitchConfig("name",e.target.value)}
            fullWidth
        />
        <TextField
            label="Twitch URL *"
            variant="outlined"
            value={twitchConfig.url}
            onChange={(e)=>hangleChangeTwitchConfig("url",e.target.value)}
            fullWidth
        />

        <FormControl fullWidth variant="outlined">
                <InputLabel id="select-label">Theme *</InputLabel>
                <Select
                labelId="select-label"
                value={twitchConfig.theme}
                onChange={(e)=>hangleChangeTwitchConfig("theme",e.target.value)}
                label="Theme *"
                >
                {Object.entries(Themes).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                        {value}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
            <Stack direction="row" sx={{alignItems:"center", marginLeft:"2px"}}>
                <Typography>Chat Box </Typography>
                <Checkbox  checked={twitchConfig.chat} onChange={()=>setTwitchConfig(prevConfig=>({...prevConfig, chat: !prevConfig.chat}))}/>
            </Stack>

        </Stack>
    </DialogContent>
    <Divider sx={{ width: '100%' }} />
    <DialogActions sx={{ backgroundColor: "#1A2432", width: "100%", padding:"8px",paddingTop:"7px", paddingBottom:"7px", 
        display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}>
            <Tooltip title="Wall must be saved to preview" arrow>
                <span> 
                <Button disabled={!isSaved} onClick={()=>{window.open(`${baseUrl}/twitchtv?chat=${twitchConfig.chat}&channel=${channel}&theme=${twitchConfig.theme == "Dark Theme" ? "dark" : "light"}`, "_blank")}} variant="contained">Preview</Button>
                </span>
            </Tooltip>

            <Stack direction="row" sx={{alignItems:"center", gap:"10px"}}>
                <Button onClick={() => onClose(false)} variant="outlined">Close</Button>
                <Tooltip title="Name and URL required" arrow>
                  <span> 
                   <Button disabled={!canSave(twitchConfig, savedTwitchConfig)} variant="contained" onClick={()=>handleSave()}>Save</Button>
                  </span>
                </Tooltip>
            </Stack>
  
    </DialogActions>
    </Dialog>
  )
}

export default DialogBoxTwitchPlaylist
