import React, { useState, useEffect, useRef } from 'react'
import {TextField, Select, MenuItem, FormControl, InputLabel, Dialog, DialogContent, DialogTitle, Box, DialogActions, IconButton, Stack, Button, Divider  } from '@mui/material';
import { IoSettingsOutline } from "react-icons/io5";
import {UnitTypes, ResolutionTypes, BackgroundMusicTypes, AudioZoneTypes, PrimaryZoneTypes} from './types';
import {Typography, Checkbox} from '@mui/material';

const settingKeys = ["Unit Type", "Resolution", "Background Music", "Audio Zone", "Primary Zone"]
const settingTypes = [UnitTypes, ResolutionTypes, BackgroundMusicTypes, AudioZoneTypes, PrimaryZoneTypes]

const DialogBoxSettings = ({setIsNewState, setSplitScreenSaved, zoneDetails, setZonesDetails, screenInfo, setScreenInfo, settingsInfo, setSettingsInfo, isOpen, onClose}) => {

    const [customs, setCustoms] = useState({width: 0, height: 0});
    const [primaryTimingZones, setPrimaryTimingZones] = useState([]);
    const scrollRef = useRef(null);
    console.log("Zone details", zoneDetails)

    // ---- Functions ----
    const changeSettings = (key,val)=>{
        setIsNewState(true)
        setSplitScreenSaved(false)
        
        setSettingsInfo(prevSettings => {
            return {...prevSettings, [key]: val}
        })
        console.log("Settings changed")
    }

    const handleAddTimingZone = (name) =>{
        setPrimaryTimingZones(prevZones => {
            if(prevZones.includes(name)){
                return prevZones.filter(zone => zone !== name)
            }
            else{
                return [...prevZones, name]
            }
        })
    }

    const handleCloseDialog = () =>{
        setZonesDetails(prevDetails => {
            return prevDetails.map(zone => {
                if(settingsInfo["Primary Zone"] == zone.name){
                    return {...zone, primary_zone: true}
                }
                else{
                    return {...zone, primaryTiming: false}
                }
            })
        })
        onClose(false)
        setScreenInfo({...screenInfo, customWidth: customs.width, customHeight: customs.height})
    }

    useEffect(()=>{
        setCustoms({width: screenInfo.customWidth, height: screenInfo.customHeight})
    },[])

    useEffect(() => {
        if (settingsInfo["Primary Zone"] !== "None" && scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [settingsInfo["Primary Zone"]]);


  return (
    <Dialog
        open={isOpen}
        onClose={() => handleCloseDialog()}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{marginBottom:"10px"}}>
            <Stack direction={"column"} sx={{alignItems:"center"}}>
                <IconButton>
                    <IoSettingsOutline size={"40px"}/>
                </IconButton>
                <Box fontSize="h4.fontSize">Split Screen Configuration</Box>
            </Stack>
        </DialogTitle>
        <DialogContent sx={{}}>
            <Stack direction={"column"} sx={{paddingLeft:"30px", paddingRight:"30px"}}>
            {settingKeys.map((key, index) => (
                <>
                    {key=="Resolution" && settingsInfo["Resolution"]=="Custom" ? 
                    <>
                        <FormControl fullWidth variant="outlined" sx={{marginTop:"20px"}} key={index}>
                            <InputLabel id="select-label">{key}</InputLabel>
                            <Select
                                labelId="select-label"
                                value={settingsInfo[key]}
                                onChange={(e)=>changeSettings(key, e.target.value)}
                                label={key}
                                >
                                {Object.entries(settingTypes[index]).map(([key, value]) => (
                                    <MenuItem key={key} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Stack direction="row" sx={{marginTop:"20px", gap:"10px"}}>
                            <TextField
                                label="Width (px)"
                                variant="outlined"
                                value={(customs.width)}
                                onChange={(e)=>setCustoms({...customs, width: e.target.value})}
                                sx={{width:"50%"}}
                            />
                            <TextField
                                label="Height (px)"
                                variant="outlined"
                                value={(customs.height)}
                                onChange={(e)=>setCustoms({...customs, height: e.target.value})}
                                sx={{width:"50%"}}
                            />
                        </Stack>
                    </>
             
                    : settingKeys[index] == "Background Music" || settingKeys[index] == "Audio Zone" || settingKeys[index] == "Primary Zone" ?
                    <FormControl fullWidth variant="outlined" sx={{marginTop:"20px"}} key={index}>
                        <InputLabel id="select-label">{key}</InputLabel>
                        <Select
                            labelId="select-label"
                            value={settingsInfo[key]}
                            onChange={(e)=>{
                                changeSettings(key, e.target.value)
                            }}
                            label={key}
                            >
                            {zoneDetails && zoneDetails?.length>0 && settingKeys[index] == "Audio Zone" ?
                            ["All", ...zoneDetails.map(zone => zone.name)].map((zone) => (
                                <MenuItem key={zone} value={zone}>
                                    {zone}
                                </MenuItem>
                            ))
                            : zoneDetails && zoneDetails?.length>0 &&
                            ["None", ...zoneDetails.map(zone => zone.name)].map((zone) => (
                                <MenuItem key={zone} value={zone}>
                                    {zone}
                                </MenuItem>
                            ))
                        }
                        </Select>
                    </FormControl>
                    :
                    <FormControl fullWidth variant="outlined" sx={{marginTop:"20px"}} key={index}>
                        <InputLabel id="select-label">{key}</InputLabel>
                        <Select
                            labelId="select-label"
                            value={settingsInfo[key]}
                            onChange={(e)=>changeSettings(key, e.target.value)}
                            label={key}
                            >
                            {Object.entries(settingTypes[index]).map(([key, value]) => (
                                <MenuItem key={key} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    }
                </>
            ))}
            {
            settingsInfo["Primary Zone"] !== "None" &&
                <div  ref={scrollRef} style={{ marginTop:"20px"}}>
                    <Typography sx={{fontSize:"14px"}}>Select Zone(s) that uses Primary Zone playlist timing</Typography>
                    {zoneDetails.length>0 && zoneDetails.filter((zone)=>zone.name !== settingsInfo["Primary Zone"]).map((zone, index) => (
                    <Stack direction={"row"} key={index} sx={{alignItems:"center"}}>
                        <Checkbox checked={primaryTimingZones.includes(zone.name)} onChange={(e)=>handleAddTimingZone(zone.name)}/>
                        <Typography>{zone.name}</Typography>
                    </Stack>
                    ))}
                </div>
            }
           

            </Stack>
        </DialogContent>

        <Divider sx={{ width: '100%', marginTop:"35px" }} />
        <DialogActions sx={{ backgroundColor: "#1A2432", width: "100%", padding:"10px" }}>
            <Button onClick={() => handleCloseDialog()} color="primary" variant="contained">
                Close
            </Button>
        </DialogActions>
    </Dialog>
  )
}

export default DialogBoxSettings