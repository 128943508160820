import React, { useState, useEffect } from "react";
import { Typography, Box, Button, Switch, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";

import { baseImg } from "../../../../api/endpoints";
import {FitScreen} from "../EditScreen/types";
import { FaBars } from "react-icons/fa6";
import { TbRotate360 } from "react-icons/tb";
import { AiFillSound } from "react-icons/ai";
import { RiVolumeMuteFill } from "react-icons/ri";

import OrientationDialog from "./OrientationDialog";
import { RotationTypes } from "../EditScreen/types";

const items = [
    // { text: 'Orientation', icon: <TbRotate360/> },
    { text: 'Mute', icon: <AiFillSound/> },
]

function PreviewPlayer({ mainWidth, mainHeight, open, onClose, player }) {
    const baseUrl = process.env.REACT_APP_BASE_URL;
  console.log(">>> Player inside preview: ", player);
  console.log(">>> Base URL: ", baseUrl);
  const hostname = new URL(baseUrl).hostname;
  console.log(">>> Base hostname: ", hostname);

  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openOrientationDialog, setOpenOrientationDialog] = useState(false);
  const [androidSettings, setAndroidSettings] = useState({orientation:RotationTypes.LANDSCAPE,audio: true});
  const [isMainElementLoaded, setIsMainElementLoaded] = useState(false);

  console.log(">>> Player: ", player);
  const handleContentFit = (content)=>{
    if (player && player.length > 0) {
        switch (content.fit_screen) {
            case FitScreen.FIT:
                return "contain";
            case FitScreen.NONE:
                console.log(">>> FitScreen.NONE: ", content);
                return "none";
            case FitScreen.ZOOM:
                return "cover";
            case FitScreen.STRETCH:
                return "fill";
            default:
                return "contain";
        }
    }
    else{
        return "contain"
    }
  }
  const handleContentZIndex = (content)=>{
    if (player && player.length > 0) {
        return content.queue * 10;
    }
    else{
        return 0
    }
}

  const getContentStyle = (content) => ({
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: handleContentFit(content),
    border: "none",  
    margin: 0,            
    padding: 0,         
    boxShadow: "none",     
    outline: "none",
    backgroundColor: "#0e1320",  
    zIndex: handleContentZIndex(content),
  });

  const rotationStyle = (orientation) => {
    let rotationDegree;
    switch (orientation) {
        case RotationTypes.LANDSCAPE:
            rotationDegree = 0;
            break;
        case RotationTypes.ROTATE_90:
            rotationDegree = 90;
            break;
        case RotationTypes.ROTATE_180:
            rotationDegree = 180;
            break;
        case RotationTypes.ROTATE_270:
            rotationDegree = 270;
            break;
        default:
            rotationDegree = 0;
    }
    return {
        transform: `rotate(${rotationDegree}deg)`,
        transformOrigin: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };
};

const loadYouTubeAPI = (callback) => {
    if (window.YT && window.YT.Player) {
      callback();
    } else {
      const script = document.createElement('script');
      script.src = 'https://www.youtube.com/iframe_api';
      script.onload = callback;
      document.body.appendChild(script);
    }
  };
  
  const loadMainElement = (mainElement) => {
    return new Promise((resolve, reject) => {
        const featureType = mainElement.type === "Playlist" ? mainElement.playlistContent.type : mainElement.type;
        const featureUrl = mainElement.type === "Playlist" ? 
            (mainElement.playlistContent.type === "widget" ? mainElement.playlistContent.widget.url : mainElement.playlistContent.url) : 
            (mainElement.type === "widget" ? mainElement.widget.url : mainElement.url);

        if (featureType === "image") {
            const img = new Image();
            document.body.appendChild(img);
            img.onload = () => {
                console.log("img loaded successfully.");
                resolve(img);
            };
            // img.onload = resolve;
            img.onerror = reject;
            img.src = `${baseImg}/${featureUrl}`;
        } else if (featureType === "video") {
            const video = document.createElement('video');
            document.body.appendChild(video);
            video.onloadeddata = () => {
                console.log("video loaded successfully.");
                resolve(video);
            };
            // video.onloadeddata = resolve;
            video.onerror = reject;
            video.src = `${baseImg}/${featureUrl}`;
        } else if (featureType === "widget") {
            if (mainElement.widget?.widgetCat === "youtube" || mainElement.widget?.widgetCat === "vimeo" || mainElement.widget?.widgetCat === "website") {
                console.log("Creating iframe for widget: ", featureUrl);
                const iframe = document.createElement('iframe');
                document.body.appendChild(iframe);

                iframe.onload = () => {
                    console.log("Iframe loaded successfully.");
                    resolve(iframe);
                };
                iframe.onerror = () => reject(new Error('Failed to load iframe.'));
                iframe.src = featureUrl;
            } else {
                resolve(); // For other widget types, assume they load instantly
            }
        } else {
            resolve(); // For other types, resolve immediately
        }
    });
    };


    useEffect(() => {
      // Funkcja do inicjalizacji graczy YouTube
      const initializePlayers = () => {
        if (window.YT && window.YT.Player) {
          const iframes = document.querySelectorAll('iframe');
          iframes.forEach(iframe => {
            if (iframe.src.includes('youtube.com')) {
              // Wartość `src` iframe bez `?` i `&` z parametrami, aby nie wprowadzać dodatkowych parametrów
              const videoId = new URL(iframe.src).searchParams.get('v');
              if (videoId) {
                new window.YT.Player(iframe, {
                  events: {
                    'onReady': (event) => {
                      event.target.mute();
                    }
                  }
                });
              }
            }
          });
        }
      };
  
      // Ładowanie API YouTube i wyciszanie filmów
      loadYouTubeAPI(initializePlayers);
  
      // Czyszczenie efektu
      return () => {
        // Opcjonalne: Możesz usunąć skrypt API, jeśli potrzebne
      };
    }, [player]);

    useEffect(() => {
        const loadMain = async () => {
            if (player && player.length > 0) {
                try {
                    const primary_player = player.filter(x => x.primary_zone === true);
                    if (primary_player.length === 0) {
                        console.log("setting main element loaded - no primary element")
                        setIsMainElementLoaded(true);
                    }
                    else{
                        console.log("setting main element loaded - there is primary element: ", primary_player[0])
                        await loadMainElement(primary_player[0]); 
                        setIsMainElementLoaded(true);
                    }
                    console.log("waiting loading; ",player[0])
                  
                } catch (error) {
                    console.error("Błąd podczas ładowania głównego elementu:", error);
                }
            }
        };

        loadMain();
    }, []);


  return (
      <Box
        sx={{
          height: `${mainHeight}px`,
          width: `${mainWidth}px`,
          maxHeight: `${mainHeight}px`,
          maxWidth: `${mainWidth}px`,
          background: "#0e1320",
          zIndex: "1000000000000",
          position: "relative" ,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setTimeout(()=>{setIsHovered(false)},2000)}
        >
        {isHovered && (
            <FaBars
            size={24}
            onClick={()=>setOpenDrawer(prev=>!prev)}
            style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                zIndex: 1000000000001,
                cursor: 'pointer',
                pointerEvents: 'auto'
            }}
            />
        )}

        {/* Dialog Component */}
        <OrientationDialog androidSettings={androidSettings} setAndroidSettings={setAndroidSettings} open={openOrientationDialog} onClose={setOpenOrientationDialog} title={"Rotation"}/>


        {/* Slide-in Panel */}
        <Box
            sx={{
            height: '100%',
            width: openDrawer ? '40%' : '0', // Slide in to 30% width
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#1c1f2b', // Panel color
            transition: 'width 0.5s ease', // Smooth transition
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto', // Handle overflow of content
            }}
        >
            <Box
                sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                backgroundColor:"#006aff",
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)', // Optional divider between items
                }}
            >
                <Typography sx={{ color: '#white', fontWeight:"bold", fontSize:"20px", margin:"auto" }}>VC Player</Typography>
            </Box>
            {/* Map through items and render rows #006aff*/}
            {items.map((item, index) => (
            <Box
                key={index}
                sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                paddingTop: '15px',
                paddingBottom: '15px',
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)', 
                cursor: 'pointer',
                transition: 'background-color 0.3s ease', // Dodaj płynne przejście
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)', // Kolor tła na hover
                },
                }}
                onClick={() => {
                    if (item.text == "Orientation") {
                        setOpenDrawer(false);
                        setOpenOrientationDialog(true);
                    }
                    if (item.text == "Mute") {
                        setAndroidSettings(prev=>({...prev,audio:!prev.audio}))
                    }
                }}         
                >

                {/* Left Column: Icon */}
                {item.text == "Mute" ? androidSettings.audio ? item.icon : <RiVolumeMuteFill/> : item.icon}
                {/* Right Column: Text */}
                <Typography sx={{ color: '#fff', marginLeft:"15px" }}>{item.text}</Typography>
                {item.text == "Mute" && <Switch sx={{marginLeft:"auto"}} checked={!androidSettings.audio} onChange={()=>setAndroidSettings(prev=>({...prev,audio:!prev.audio}))}/>}
          </Box>
            ))}
        </Box>



        {/* Dimmed Overlay */}
        {(openDrawer || openOrientationDialog) && (
            <Box
            sx={{
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dimmed effect
                transition: 'opacity 0.5s ease', // Smooth transition
                zIndex: 999, // Below the slide-in panel
            }}
            onClick={() => {
                setOpenDrawer(false)
                setOpenOrientationDialog(false)
            }} // Close the panel when clicked
            />
        )}

        {/* Render Player Elements */}
        {player && player.length > 0 && (
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    ...rotationStyle // Apply rotation to container of content
                }}
            >
        {isMainElementLoaded && player && player.length > 0 && 
        player.map((x, index) => {
            const featureType = x.type == "Playlist" ? x.playlistContent.type : x.type;
            const featureUrl = x.type == "Playlist" ? x.playlistContent.type == "widget" ? x.playlistContent.widget.url : x.playlistContent.url : x.type=="widget" ? x.widget.url : x.url;
            const featureHtml = x.type == "Playlist" ? x.playlistContent.widget?.html : x.widget?.html
            const widgetCat = x.type == "Playlist" ? x.playlistContent.widget?.widgetCat : x.widget?.widgetCat
    

            console.log(">>> Feature Type: ", featureType);
            console.log(">>> Feature Url: ", featureUrl);
            console.log(">>> Widget Cat: ", widgetCat);
            // console.log(">>> Window local: ", hostname);

            return (
                <Box key={index}
                    sx={{
                        height: `${x.height}px`,
                        width: `${x.width}px`,
                        position: "absolute", 
                        left: `${x.x}px`, 
                        top: `${x.y}px`, 
                        backgroundColor: "#0e1320",
                        zIndex: x.queue * 10,
                        overflow: "hidden",
                    }}
                    >
                        {featureType === "image" && <img src={`${baseImg}/${featureUrl}`} style={getContentStyle(x)} />}
                        {featureType === "video" && <video muted={androidSettings.audio} autoPlay src={`${baseImg}/${featureUrl}`} style={getContentStyle(x)}></video>}
                        {player.embed === "embed" && <embed src={`${featureUrl}`} style={{ height: "100%", width: "100%", objectFit: "contain" }} controls />}
                        {featureType === "widget" && (
                            <>
                            {widgetCat === "youtube" && <iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" src={featureUrl+"?controls=0&modestbranding=1"} style={getContentStyle(x)}></iframe>}
                            {widgetCat === "vimeo" && <iframe muted={androidSettings.audio} src={featureUrl} style={getContentStyle(x)}></iframe>}
                            {widgetCat === "website" && <iframe muted={androidSettings.audio} src={featureUrl} style={getContentStyle(true)}></iframe>}
                            {/* {widgetCat === "scrollingstrip" && <div style={getContentStyle(x)} id="scrollingstrip"></div>} */}
                            {widgetCat === "scrollingstrip" && <div style={{ height: "100%", width: "100%" }} dangerouslySetInnerHTML={{ __html: featureHtml }}></div>}
                            {widgetCat === "canva" && <div style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                maxHeight: "100%",
                                                                zIndex: 999999999999,
                                                                overflow: "auto",
                                                            }} dangerouslySetInnerHTML={{ __html: featureHtml.replaceAll(">\\n", "<br/>").replaceAll("\\", "") }}></div>}
                            {widgetCat === "embed" && <div style={{ height: "100%", width: "100%" }} dangerouslySetInnerHTML={{ __html: featureHtml }}></div>}
                            {widgetCat === "weather" && <div style={{ height: "100%", width: "100%" }} dangerouslySetInnerHTML={{ __html: featureHtml }}></div>}
                            {widgetCat === "yt_playlist" && (
                                <iframe
                                    title="YouTube Playlist"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    src={`${featureUrl}`}
                                    style={getContentStyle(x)}
                                ></iframe>
                            )}
                            </>
                            )}
                            {widgetCat === "twitch" && (
                                <iframe
                                    title="Twitch Live Stream"
                                    src={`https://player.twitch.tv/?channel=${featureUrl.split('/').pop()}&parent=${hostname}`} // Replace 'example.com' with your domain
                                    height="100%"
                                    width="100%"
                                    frameBorder="0"
                                    scrolling="no"
                                    allowFullScreen
                                ></iframe>
                            )}
                </Box>
            );
            })}
      </Box>
    )}
    </Box>

  );
}

export default PreviewPlayer;
