import React, {useState, useEffect, useRef, useMemo} from 'react';
import { Dialog, DialogContent, DialogTitle, Box, Grid, IconButton, Divider, DialogActions,Button,Typography } from '@mui/material';
import toast from "react-hot-toast";
import CloseIcon from '@mui/icons-material/Close';
import ZoneCanvasControl from './ZoneCanvasControl';
import ZoneDetailsControl from './ZoneDetailsControl';
import { FitScreen, Orientation, ContentType } from './types';
import { resolutionCounters,  UnitTypes, ResolutionTypes, BackgroundMusicTypes, AudioZoneTypes, PrimaryZoneTypes } from './types';
import {
  addContent,
  editContent,
} from "src/redux/actions/Content";
import { useDispatch, useSelector, shallowEqual } from "react-redux";


import {getPlaylistsById} from "../../../../redux/actions/Playlist";
import { addSplitScreen } from 'src/redux/actions/SplitScreen';
import {updateScreen} from "src/redux/actions/Screen";

const DialogBoxEditScreen = ({setRefreshData, id, isOpen, onClose, orientation, rectanglesUnfolding, isNew }) => {
  const dispatch = useDispatch();
    const { user} = useSelector((state) => state.User);
    const { currentFolder, allFolders, userFolders, contentFolders, files, isFolderCreated, isFolderDeleted } = useSelector(
      (state) => ({
        currentFolder: state.FileFolder.currentFolder,
      }),
      shallowEqual
    );

    const { playlists } = useSelector((state) => state.Playlist);

    // ---- useStates ----
    const [settingsInfo, setSettingsInfo] = React.useState(isNew ? {
      "Unit Type": UnitTypes.PERCENTAGE,
      "Resolution": ResolutionTypes.HD,
      "Background Music": BackgroundMusicTypes.NONE,
      "Audio Zone": AudioZoneTypes.ALL,
      "Primary Zone": PrimaryZoneTypes.NONE,
  }:
  rectanglesUnfolding?.settingsInfo ? rectanglesUnfolding?.settingsInfo : {
    "Unit Type": UnitTypes.PERCENTAGE,
    "Resolution": ResolutionTypes.HD,
    "Background Music": BackgroundMusicTypes.NONE,
    "Audio Zone": AudioZoneTypes.ALL,
    "Primary Zone": PrimaryZoneTypes.NONE,
  }
)
    const [screenInfo, setScreenInfo] = useState({name: isNew ? "" : rectanglesUnfolding?.name, orientation: Orientation.LANDSCAPE, customWidth: 0, customHeight: 0});
    const [zonesDetails, setZonesDetails] = useState([{
      name:"",id:"",x:0,y:0,width:0,height:0,type:ContentType.ASSET,content_name:"",content_path:"",fit_screen:FitScreen.FIT,
      audio_zone:true, bg_music_zone:false, primary_zone:false
    }]); // Zones details
    const [zonesHistory, setZonesHistory] = useState([]); // Zones history
    const [historyPointer, setHistoryPointer] = useState(0);
    const [stageSize, setStageSize] = useState({
      width: window.innerWidth * 0.4 ,
      height: window.innerWidth * 0.4 / resolutionCounters.HD,
    });
    const [selectedId, setSelectedId] = useState(null);
    const [splitScreenSaved, setSplitScreenSaved] = useState(false);
    const [splitScreenWasSaved, setSplitScreenWasSaved] = useState(false);
    const [isNewState, setIsNewState] = useState(isNew);
    const [savedId, setSavedId] = useState(null);


    // console.log("Rectangles unfolding: ", rectanglesUnfolding)
    useEffect(() => {
      if (rectanglesUnfolding){
        setScreenInfo((prev)=>{return {...prev, name:rectanglesUnfolding?.name}})
        console.log("Rectangles unfolding use effectt: ", rectanglesUnfolding?.settingsInfo)
        setSettingsInfo((prev)=>{return rectanglesUnfolding?.settingsInfo ? rectanglesUnfolding?.settingsInfo : prev})
      }
    },[rectanglesUnfolding])

    const onSyncScreen = (_mac) => {
      // const relaventScreen = allScreens.find((s) => s.mac === _mac);
      // //  console.log(_mac)
      // socketRef?.current?.emit("sync", { detail: { mac: _mac } }, function () {
      //   toast.success(`Sync succefully on ${relaventScreen?.deviceName}`);
      // });
    };

    const editScreen = async () => {
      // if (user?.isMember) {
      //   await dispatch(
      //     updateScreen(screen._id, {
      //       playlistId,
      //       oriantation,
      //       deviceName,
      //       type,
      //       syncPlay,
      //       showDownloadStatus,
      //       showOfflineIndicator,
      //       preloadAssetPlaylist,
      //       playBackControl,
      //       brightness,
      //       volume,
      //       mute,
      //       location,
      //       backgroundType: {
      //         type: backgroundType,
      //         value: colorCode,
      //       },
      //       subuser: user?.user?._id,
      //       platform: "socialMedia",
      //       role: user?.user?.role,
      //       folderId: currentFolder,
      //       lat,
      //       lng,
      //     })
      //   ).then(() => {
      //     onSyncScreen(screen.mac);
      //   });
      // } else {
      //   await dispatch(
      //     updateScreen(screen._id, {
      //       playlistId,
      //       oriantation,
      //       deviceName,
      //       type,
      //       syncPlay,
      //       showDownloadStatus,
      //       showOfflineIndicator,
      //       preloadAssetPlaylist,
      //       playBackControl,
      //       brightness,
      //       volume,
      //       mute,
      //       location,
      //       backgroundType: {
      //         type: backgroundType,
      //         value: colorCode,
      //       },
      //       user: user?._id,
      //       subuser: user?._id,
      //       role: user?.role,
      //       folderId: currentFolder,
      //       lat,
      //       lng,
      //     })
      //   ).then(() => {
      //     onSyncScreen(screen.mac);
      //   });
      // }
    };

    const debounceTimeout = useRef(null);
    useEffect(() => {
      if (!isNew){
        if(id){
          setSavedId(id)
        }
      }
    },[id]);
    // ---- Functions ----
    const isAnyChosenZoneMedia = ()=>{
      let isAnyChosen = false;
      if (!screenInfo.name) return false;
      zonesDetails?.forEach((player,cnt) => {
        if (player.type && (player.content_name || player.object)){
          isAnyChosen = true;
          return;
        }
      });
      return isAnyChosen && screenInfo.name !== ""
    }

    const changeScreenInfo = (key, value) => {
      console.log("Changin screen info, split screen saved: ", splitScreenSaved)
      // if (splitScreenSaved) setSplitScreenSaved(false);
      // if (isNew && isNewState) setIsNewState(true);
      setSplitScreenSaved(false);
      setIsNewState(true);
      
      setScreenInfo(prevScreenInfo => {
        return {...prevScreenInfo, [key]: value}
      })

      if (key === "orientation"){
        if (value === Orientation.LANDSCAPE){
          setStageSize(prevStageSize => {
            return {width: window.innerWidth * 0.4, height: window.innerWidth * 0.4 / resolutionCounters.HD}
          })
        }
        else if (value === Orientation.PORTRAIT){
          setStageSize(prevStageSize => {
            return {width: window.innerHeight * 0.5 / resolutionCounters.HD, height: window.innerHeight * 0.5}
          })
        }
      }
    }

    const handleSaveSplitScreen = async () => {
      const scaled_zones = zonesDetails.map(zone => {
        return {
          ...zone,
          x: zone.x / stageSize.width,
          y: zone.y / stageSize.height,
          width: zone.width / stageSize.width,
          height: zone.height / stageSize.height
        }
      })
      if ((isNew && splitScreenWasSaved) || (!isNew)){
        console.log("EDIT SPLIT SCREEN")
        if (user?.isMember) {
          const content = await dispatch(
            editContent({
              object:{
              type: "split_screen",
              name: screenInfo.name,
              screenInfo: screenInfo,
              settingsInfo: settingsInfo,
              user: user.user._id,
              role: user?.user?.role,
              zones: scaled_zones,
              orientation: screenInfo.orientation,
              customWidth: screenInfo.customWidth,
              customHeight: screenInfo.customHeight,
              folderId: currentFolder,
              },
              id: savedId
            })  
          );
          if (content && content?.id){
            setSavedId(content.id);
          }
          console.log("EDITED CONTETNETNTEN", content.id)
        } else {
          const content =await dispatch(
            editContent({
              object:{
              type: "split_screen",
              name: screenInfo.name,
              screenInfo: screenInfo,
              settingsInfo: settingsInfo,
              user: user?._id,
              subuser: user?._id,
              role: user?.role,
              zones: scaled_zones,
              orientation: screenInfo.orientation,
              customWidth: screenInfo.customWidth,
              customHeight: screenInfo.customHeight,
              folderId: currentFolder,
              },
              id: savedId
            })  
          );
          if (content && content?.id){
            setSavedId(content.id);
          }
        }
      }

      else{
        if (user?.isMember) {
          const content = await dispatch(
            addContent({
              type: "split_screen",
              name: screenInfo.name,
              screenInfo: screenInfo,
              settingsInfo: settingsInfo,
              user: user.user._id,
              role: user?.user?.role,
              zones: scaled_zones,
              orientation: screenInfo.orientation,
              customWidth: screenInfo.customWidth,
              customHeight: screenInfo.customHeight,
              folderId: currentFolder,
            })
          );
          if (content && content?.id){
            setSavedId(content.id);
          }
        } else {
          const content =await dispatch(
            addContent({
              type: "split_screen",
              name: screenInfo.name,
              screenInfo: screenInfo,
              settingsInfo: settingsInfo,
              user: user?._id,
              subuser: user?._id,
              role: user?.role,
              zones: scaled_zones,
              orientation: screenInfo.orientation,
              customWidth: screenInfo.customWidth,
              customHeight: screenInfo.customHeight,
              folderId: currentFolder,
            })
          );
          if (content && content?.id){
            setSavedId(content.id);
          }
  
        }
      }

      setSplitScreenSaved(true);
      setSplitScreenWasSaved(true);
      if (setRefreshData){
        setRefreshData(prev=>!prev);
      }
      // onClose(false);
    }

    const changeZoneDetails = (index, key, value) => {
      console.log("Changin zone details, split screen saved: ", splitScreenSaved)
      setSplitScreenSaved(false);
      setIsNewState(true);

      var newZoneDetails = [];
      if (Array.isArray(value) && Array.isArray(key)){
        newZoneDetails = zonesDetails.map((zone, i) => {
          if (i === index) {
            // Tworzymy nowy obiekt z podmienionymi kluczami i wartościami
            let updatedZone = { ...zone };
            key.forEach((k, idx) => {
              updatedZone[k] = value[idx];
            });
            return updatedZone;
          }
          return zone;
        });
      
        setZonesDetails(newZoneDetails)
      }

      else{
        newZoneDetails = zonesDetails.map((zone, i) => {
          if (i === index){
            return {...zone, [key]: value}
          }
          return zone;
        })
        setZonesDetails(newZoneDetails)
      }

      // Change History
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        if (historyPointer == 0){
          setZonesHistory(prev => [...prev, newZoneDetails]);
        }
        else{
          setZonesHistory(prev => [...prev.slice(0,zonesHistory.length + historyPointer-1), newZoneDetails]);
          setHistoryPointer(0);
        }
      }, 1500);
    }

    // ---- useEffects ----
    useEffect(() => {
      if (isNew){
        setScreenInfo((prev)=>{
          return {
            ...prev,
            name:rectanglesUnfolding?.name,
          }
        })
      }
    }, [rectanglesUnfolding]);

    useEffect(() => {
      if (user?.isMember) {
        dispatch(getPlaylistsById(user?.user?._id));
      } else {
        dispatch(getPlaylistsById(user?._id));
      }
    }, [user]);


    useEffect(() => {
      if (isNew){
        const newZonesSet = rectanglesUnfolding.map((item, index) => {
          return {
            name: `Zone ${index+1}`,
            x: item.startX * stageSize.width,
            y: item.startY * stageSize.height,
            width: item.w_factor * stageSize.width,
            height: item.h_factor * stageSize.height,
            fill: item.fill,
            fit_screen: FitScreen.FIT,
            type:ContentType.NONE,
            opacity: 0.7,
            id: `rect${index}`,
            scaleX: 1,
            scaleY: 1,
            audio_zone:true, 
            bg_music_zone:false, 
            primary_zone:false
          }})
          setZonesDetails(newZonesSet)
          setZonesHistory([newZonesSet]);
        }
      else{
        const newZonesSet = rectanglesUnfolding?.zones && 
          rectanglesUnfolding.zones.map((item, index) => {
            const zonesBase = {
              name: item.name,
              x: item.x * stageSize.width,
              y: item.y * stageSize.height,
              width: item.width * stageSize.width,
              height: item.height* stageSize.height,
              fill: item.fill,
              fit_screen: item.fit_screen,
              type:item.type,
              opacity: item.opacity,
              id: item.id,
              scaleX: item.scaleX,
              scaleY: item.scaleY,
              audio_zone: item.audio_zone, 
              bg_music_zone: item.bg_music_zone, 
              primary_zone:item.primary_zone,
            }

            if (item?.type === ContentType.PLAYLIST){
              return {
                ...zonesBase,
                object:item.object
              }
            }
            if (item?.type === ContentType.ASSET){
              return {
                ...zonesBase,
                content_name:item.content_name,
                content_path:item.content_path,
                object:item.object
              }
            }
          return zonesBase
        })
        setZonesDetails(newZonesSet)
        setZonesHistory([newZonesSet]);
      }
      
    },[rectanglesUnfolding, stageSize]);

    useEffect(() => {
      if (zonesHistory.length > 0){
        setZonesDetails(zonesHistory[zonesHistory.length + historyPointer-1]);
      }
    }, [historyPointer])

    useEffect(() => {
      const handleResize = () => {
        if (orientation === Orientation.LANDSCAPE){
          setScreenInfo(prevScreenInfo => {
            return {...prevScreenInfo, orientation: Orientation.LANDSCAPE}
          })
          setStageSize(prevStageSize => {
            return {width: window.innerWidth * 0.4, height: window.innerWidth * 0.4 / resolutionCounters.HD}
          })
        }
        else if (orientation === Orientation.PORTRAIT){
          setScreenInfo(prevScreenInfo => {
            return {...prevScreenInfo, orientation: Orientation.PORTRAIT}
          })
          setStageSize(prevStageSize => {
            return {width: window.innerHeight * 0.5 / resolutionCounters.HD, height: window.innerHeight * 0.5}
          })
        }
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [orientation]);

    // Change screen size on orientation change - orientation chosen during screen creation
    useEffect(() => {
      if (orientation === Orientation.LANDSCAPE){
        setScreenInfo(prevScreenInfo => {
          return {...prevScreenInfo, orientation: Orientation.LANDSCAPE}
        })
        setStageSize(prevStageSize => {
          return {width: window.innerWidth * 0.4, height: window.innerWidth * 0.4 / resolutionCounters.HD}
        })
      }
      else if (orientation === Orientation.PORTRAIT){
        setScreenInfo(prevScreenInfo => {
          return {...prevScreenInfo, orientation: Orientation.PORTRAIT}
        })
        setStageSize(prevStageSize => {
          return {width: window.innerHeight * 0.5 / resolutionCounters.HD, height: window.innerHeight * 0.5}
        })
      }
    },[orientation]);


    useEffect(() => {
      const htmlElement = document.querySelector('html');
      if (isOpen && !htmlElement.classList.contains('overflow-hidden')) {
        htmlElement.classList.add('overflow-hidden');
      } else {
        htmlElement.classList.remove('overflow-hidden');
      }
    }, [isOpen]);
    // ---- useMemo ----
    const memoizedZoneDetailsControl = useMemo(() => (
      <ZoneDetailsControl
        playlists={playlists}
        settingsInfo={settingsInfo}
        selectedId={selectedId}
        screenInfo={screenInfo}
        changeScreenInfo={changeScreenInfo}
        zones={zonesDetails}
        changeZoneDetails={changeZoneDetails}
        stageSize={stageSize}
        setSplitScreenSaved={setSplitScreenSaved} 
        setIsNewState={setIsNewState}
      />
    ), [
      playlists,
      settingsInfo,
      selectedId,
      screenInfo,
      changeScreenInfo,
      zonesDetails,
      changeZoneDetails,
      stageSize,
    ]);

  return (
    <Dialog
      fullScreen 
      open={isOpen}
      onClose={() => {
        setSplitScreenSaved(false)
        setSplitScreenWasSaved(false)
        setSavedId(null)      
        onClose(false)
      }}
      sx={{
        zIndex: 1300, // Zwiększenie, jeśli inne elementy w tle nachodzą
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: "#1A2432", padding: "12px", position: 'relative',  overflow: "hidden"}} // 2*8px = 16px (top+ bottom = 32px)
      >
        <IconButton
          onClick={() => {
            setSplitScreenSaved(false)
            setSplitScreenWasSaved(false)
            setSavedId(null)                
            onClose(false)
          }}
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            color: 'black',
            zIndex: 1300, 
          }}
        >
          <CloseIcon style={{ color: 'white' }} />
        </IconButton>
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold", color:"white"}} style={{fontSize:"32px" }}> 
          Edit Zones
        </DialogTitle>
      </Box>
      <Divider sx={{ width: '100%' }} />


      <DialogContent sx={{ padding: 2, height: 'calc(100vh - 128px)' }}>  {/* Dodanie paddingu i wysokości */}
      <Grid container spacing={2}  sx={{ height: '100%', maxHeight:  'calc(100vh - 128px)', padding:"20px" }}>
        <Grid item xs={12} md={4} >
            {memoizedZoneDetailsControl}
        </Grid>
        <Grid item xs={12} md={8}>
            <ZoneCanvasControl setIsNewState={setIsNewState} setSplitScreenSaved={setSplitScreenSaved} screenInfo={screenInfo} setScreenInfo={setScreenInfo} settingsInfo={settingsInfo} setSettingsInfo = {setSettingsInfo} selectedId={selectedId} setSelectedId={setSelectedId} zoneDetails={zonesDetails} setZonesDetails={setZonesDetails} zonesHistory={zonesHistory} setZonesHistory={setZonesHistory} historyPointer={historyPointer} setHistoryPointer={setHistoryPointer} stageSize={stageSize}/>
        </Grid>
      </Grid>
      </DialogContent>
      <Divider sx={{ width: '100%' }} />
        <DialogActions sx={{ backgroundColor: "#1A2432", width: "100%", padding:"8px",paddingTop:"7px", paddingBottom:"7px", 
            display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center",    
           '@media (max-width: 600px)': {
            flexDirection: "column", 
          }, }}>
          <Typography   sx={{
            fontFamily: "'Arial', sans-serif",
            fontSize: '16px',
            color: '#D32F2F', 
            textAlign: 'center',
            margin:"auto",
            paddingLeft:"50px",
            wordBreak: 'break-word', 
            overflowWrap: 'break-word',
            '@media (max-width: 600px)': {
              marginLeft:"0px",
              paddingLeft:"0px",
              padding:"5px"
          }
          }}
  >4 zones or more may have performance & stability issues on Amazon FireTV, TV Boxes, and TVs, and other Android devices.</Typography>
            <Button disabled={(!isAnyChosenZoneMedia()) || splitScreenSaved || (isNew && splitScreenSaved) || (!isNew && !isNewState || splitScreenSaved)} onClick={handleSaveSplitScreen} color="primary" variant="contained" 
                sx={{              
                  '&.Mui-disabled': { backgroundColor: "#5A7690"},marginRight:"0px",  '@media (max-width: 600px)': { marginLeft:"0px" }}}>
                Save
            </Button>
        </DialogActions>
    </Dialog>
  );
};

export default DialogBoxEditScreen;
