import { Box, Button, Typography, SvgIcon } from "@mui/material";
import React, { useCallback, useEffect, Fragment, useRef, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../components/layout/Layout";
import Divider from "@mui/material/Divider";

import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { useDispatch, useSelector } from "react-redux";
import {
  addPlaylist,
  deleteContentInPlaylist,
  deletePlaylist,
  findOne,
  getPlaylistsById,
  updateDuration,
  editPlaylistName,
  addContentInPlaylist,
  playlistFlagOff,
  editPlaylistDemoAttribute,
} from "../../redux/actions/Playlist";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";

import { useMediaQuery } from "react-responsive";
import toast from "react-hot-toast";
import AddPlaylistModal from "./component/AddPlaylistModal";
import SelectFilesModal from "./component/SelectFilesModal";

import { useDialog } from "src/hooks/use-dialog";

import { OrderDrawer } from "./component/order-drawer";
import { OrderListContainer } from "./component/order-list-container";
import { OrderListSearch } from "./component/order-list-search";
import { OrderListTable } from "./component/order-list-table";
import { useSettings } from "src/hooks/use-settings";

function RedesignPlaylist({ socketRef, user }) {
  const { t } = useTranslation();
  const rootRef = useRef(null);
  const settings = useSettings();

  const dialog = useDialog();
  const dispatch = useDispatch();
  const findOnePlaylistAndActive = (id) => {
    dispatch(findOne(id));
  };

  const handleDrawerOpen = (id) => {
    findOnePlaylistAndActive(id);
    if (dialog.open && dialog.data === id) {
      dialog.handleClose();
      return;
    }

    dialog.handleOpen(id);
  };

  const { playlists, playlist, isOnePlaylist, isPlaylistLoaded, isContentAdded, isPlaylistContentSorted } = useSelector((state) => state.Playlist);
  const { screens } = useSelector((state) => state.Screen);
  const { content } = useSelector((state) => state.Content);
  const [search, setSearch] = useState("");
  const [opneCreatePlaylist, setOpenCreatePlaylist] = useState(false);
  const [openFolder, setOpenFolder] = useState(false);
  const [playlistName, setPlaylistName] = useState("");
  const [defaultPlaylist, setDefaultPlaylist] = useState(false);
  const [foldername, setFolderName] = useState("");
  const [playlistControlOption, setPlaylistControlOption] = useState(false);
  const [openTimeModel, setOpenTimeModel] = useState(false);
  const [openSelectFiles, setOpenSelectFiles] = useState(false);
  const [time, setTime] = useState(0);
  const [contentId, setContentId] = useState("");
  const [anyId, setAnyId] = useState("");
  const [playlistId, setPlaylistId] = useState("");
  const [openPlaylist, setOpenPlaylist] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [overContent, setOverContent] = useState(false);
  const [contentObjectValue, setContentObjectValue] = useState({});
  const [lastContentId, setLastContentId] = useState("");
  const [isMainContent, setIsMainContent] = useState(false);
  const [one, setOne] = useState({});
  const [items, setItems] = useState([]);
  const [isPending, startTransition] = useTransition();
  // console.log(playlists);
  useEffect(() => {
    setItems(playlists);
  }, [playlists]);

  useEffect(() => {
    if (user?.isMember) {
      dispatch(getPlaylistsById(user?.user?._id));
    } else {
      dispatch(getPlaylistsById(user?._id));
    }
  }, [user]);

  useEffect(() => {
    if (isPlaylistLoaded) {
      dispatch(findOne(playlists[0]?._id));
      dispatch(playlistFlagOff());
    }
    if (isContentAdded) {
      toast.success(t("views.redesignPlaylist.contentAddedSuccess"));
      // emitOnAddedContentInPlaylist();
      dispatch(playlistFlagOff());
    }
    if (isPlaylistContentSorted) {
      toast.success(t("views.redesignPlaylist.contentSortedSuccess"));
      // emitOnAddedContentInPlaylist();
      dispatch(playlistFlagOff());
    }
  }, [isPlaylistLoaded, isContentAdded, isPlaylistContentSorted]);

  useEffect(() => {
    if (isOnePlaylist) {
      setPlaylistId(playlist._id);
      setOne({ ...playlist });
      dispatch(playlistFlagOff());
    }
  }, [isOnePlaylist]);

  const onSelectFiles = () => {
    setOpenSelectFiles(true);
  };

  const savePlaylist = () => {
    const isSameNamePlaylist = playlists.filter((p) => p.playlistName === playlistName);
    if (playlistName === "" || playlistName === undefined) {
      return;
    }
    if (isSameNamePlaylist.length > 0) {
      toast.error(t("views.redesignPlaylist.sameNamePlaylistError"));
    } else {
      // console.log(defaultPlaylist);
      if (user?.isMember) {
        dispatch(
          addPlaylist({
            playlistName,
            defaultPlaylist,
            user: user.user._id,
            role: user?.user?.role,
          })
        );
      } else {
        dispatch(
          addPlaylist({
            playlistName,
            defaultPlaylist,
            user: user?._id,
            subuser: user?._id,
            role: user?.role,
          })
        );
      }
      setOpenPlaylist(false);
      setPlaylistName("");
      setDefaultPlaylist(false);
    }
  };

  const onSaveFiles = (ids) => {
    // console.log(ids);
    let arrayOfFiles = [];
    ids.forEach((id) => {
      arrayOfFiles.push({
        id: playlist._id,
        value: id,
      });
    });
    dispatch(addContentInPlaylist(arrayOfFiles));
  };

  const deleteContentFromPlaylist = (obj) => {
    dispatch(deleteContentInPlaylist(obj));
    const s = screens.find((c) => c?.playlist?._id === obj.playlistId);
    if (s !== undefined || s !== null) {
      // emitOnDeleteContentFromPlaylist(s?.mac);
    }
  };

  const saveDurationChange = (time, playlistid, playlistContentId) => {
    dispatch(
      updateDuration({
        time: time,
        playlistId: playlistid,
        contentId: playlistContentId,
      })
    );
    const s = screens.find((c) => c?.playlist?._id === playlistid);
    if (s !== undefined || s !== null) {
      // emitOnTimeDurationChangedInPlaylist(s?.mac);
    }
  };

  const onSetDemoPlaylist = (id, isDemo) => {
    dispatch(editPlaylistDemoAttribute(id, !isDemo));
  };

  const deletePlaylists = () => {
    // console.log(playlist._id + " " + playlist.playlistName);
    dispatch(deletePlaylist([playlist._id]));
    // emitOnDeletePlaylist([id]);
  };

  const editPlaylistNameSave = (id, playlistName) => {
    dispatch(editPlaylistName(id, playlistName));
  };

  const handleSearchChange = useCallback(
    (value) => {
      if (playlists && playlists.length > 0) {
        startTransition(() => {
          setItems(playlists.filter((item) => item.playlistName.toLowerCase().includes(value)));
        });
      }
    },
    [playlists]
  );

  const handleSortChange = useCallback(
    (value) => {
      // console.log(value);

      startTransition(() => {
        if (value == "date_desc" || value == "date_asc") {
          setItems(playlists.reverse());
        } else if (value == "name_asc") {
          setItems(playlists.sort((a, b) => a.playlistName.localeCompare(b.playlistName)));
        } else if (value == "name_desc") {
          setItems(playlists.sort((a, b) => -1 * a.playlistName.localeCompare(b.playlistName)));
        }
      });
    },
    [items, playlists]
  );

  const onPlaylistAdd = () => {
    setOpenPlaylist(true);
  };

  const onSync = () => {
    for (const p in playlist.screen_mac) {
      socketRef?.current.emit(
        "sync",
        {
          detail: { mac: playlist.screen_mac[p].mac },
        },
        function () {}
      );
    }

    toast.success(t("views.redesignScreens.syncSuccess"));
  };

  return (
    <Fragment>
      <Layout title={t("views.redesignPlaylist.title")} user={user}>
        <Box component="main" sx={{ flexGrow: 1, py: 2 }}>
          <Container maxWidth={settings.stretch ? false : "xl"}>
            <Grid container spacing={{ xs: 3, lg: 4 }}>
              <Grid xs={12}>
                <Stack direction="row" justifyContent="space-between" spacing={4}>
                  <div>
                    {/* <Typography variant="h4">{t("views.redesignPlaylist.playlist")}</Typography> */}
                  </div>
                  <div>
                    <Button
                      onClick={onPlaylistAdd}
                      startIcon={
                        <SvgIcon>
                          <PlaylistAddIcon />
                        </SvgIcon>
                      }
                      variant="contained">
                      {t("views.redesignPlaylist.add")}
                    </Button>
                  </div>
                </Stack>
              </Grid>

              <Grid xs={12}>
                <Box
                  ref={rootRef}
                  sx={{
                    display: "flex",
                  }}>
                  <OrderListContainer open={dialog.open}>
                    <OrderListSearch onFiltersChange={handleSearchChange} onSortChange={handleSortChange} />
                    <Divider />
                    <OrderListTable items={items} onSelect={(id) => handleDrawerOpen(id)} />
                  </OrderListContainer>
                  <OrderDrawer
                    container={rootRef.current}
                    onClose={dialog.handleClose}
                    open={dialog.open}
                    order={playlist}
                    orders={playlists}
                    onSelectFiles={onSelectFiles}
                    deletePlaylists={deletePlaylists}
                    editPlaylistNameSave={editPlaylistNameSave}
                    saveDurationChange={saveDurationChange}
                    onSync={onSync}
                    deleteContentFromPlaylist={deleteContentFromPlaylist}
                    isAdmin={user?.role === "admin" || false}
                    onSetDemoPlaylist={onSetDemoPlaylist}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Layout>

      <SelectFilesModal
        open={openSelectFiles}
        onClose={() => setOpenSelectFiles(false)}
        setOpenSelectFiles={setOpenSelectFiles}
        files={content}
        playlist={playlist}
        onSaveFiles={onSaveFiles}></SelectFilesModal>

      <AddPlaylistModal
        openCreatePlaylist={openPlaylist}
        value={playlistName}
        textValue={(e) => setPlaylistName(e)}
        onClose={(e) => setOpenPlaylist(e)}
        savePlaylist={savePlaylist}
        isAdmin={user?.role === "admin" || false}
        defaultPlaylist={defaultPlaylist}
        setDefaultPlaylist={setDefaultPlaylist}
      />
    </Fragment>
  );
}

export default RedesignPlaylist;
