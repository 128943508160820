import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Divider, Box, TablePagination } from "@mui/material";

import { baseImg } from "../../../api/endpoints";
import ContentCard from "../../../components/contentcard/ContentCard";
import ListViewFile from "./ListViewFile";
import DialogBoxEditScreen from "./EditScreen/DialogBoxEditScreen"
import DialogBoxTwitchPlaylist from "../Widgets/DialogBoxTwitchPlaylist";
import DialogBoxYoutubePlaylist from "../Widgets/DialogBoxYoutubePlaylist";

function All({
  setRefreshData,
  content,
  onAddPlaylist,
  onPushScreen,
  onDelete,
  onMove,
  onPreview,
  view,
  onCheckedSingleContent,
  onUncheckedSingleContent,
  onUncheckedContent,
  handleSelectAll,
}) {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { t } = useTranslation();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openSplitScreenDialog, setOpenSplitScreenDialog] = React.useState(false)
  const [openTwitchDialog, setOpenTwitchDialog] = React.useState(false)
  const [openYoutubeDialog, setOpenYoutubeDialog] = React.useState(false)

  const [savedZones, setSavedZones] = React.useState({})


  const ssId = React.useRef(null)
  const sz = React.useRef(null)
  const twitch = React.useRef(null)
  const youtube = React.useRef(null)

  const contentToView = [...content].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    if (handleSelectAll) {
      contentToView.forEach((c) => onCheckedSingleContent(c.id));
    }
  }, [handleSelectAll]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onUncheckedContent();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    onUncheckedContent();
  };

  const handleSeeSplitScreen = (contentObject) =>{
    console.log("see split", contentObject)
    ssId.current = contentObject.id
    setSavedZones(contentObject)
    sz.current = contentObject
    setOpenSplitScreenDialog(true)
  }

  const handleSeeTwitchDialog = (contentObject) =>{
    console.log("see twitch", contentObject)
    twitch.current = contentObject
    setOpenTwitchDialog(true)
  }

  const handleSeeYoutubeDialog = (contentObject) =>{
    console.log("see youtube", contentObject)
    youtube.current = contentObject
    setOpenYoutubeDialog(true)
  }

  const handleTwitchPreview = (contentObject) =>{
    const parts = contentObject?.widget?.url?.split('/');
    const channel = parts[parts.length - 1];
    const chat = contentObject?.widget?.chat || "true"
    let theme = "light"
    if (contentObject?.widget?.theme == "Dark Theme"){
      theme = "dark"
    }

    window.open(`${baseUrl}/twitchtv?chat=${chat}&channel=${channel}&theme=${theme}`, "_blank")
  }

  return (
    <>
      <DialogBoxEditScreen setRefreshData={setRefreshData} id={ssId.current} isOpen={openSplitScreenDialog} onClose={setOpenSplitScreenDialog} orientation={ sz.current?.orientation || "portrait"} rectanglesUnfolding={ sz.current} isNew={false}/>
      <DialogBoxTwitchPlaylist  object={twitch.current} isNew={false} isOpen={openTwitchDialog} onClose={setOpenTwitchDialog} />
      <DialogBoxYoutubePlaylist object={youtube.current} isNew={false} isOpen={openYoutubeDialog} onClose={setOpenYoutubeDialog} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          flexWrap: "wrap",
          margin: "10px 0",
        }}>
        {view
          ? contentToView.map((data, index) => {
            if (data?.type == "split_screen"){
              return (
                // <div>TUTAJ SPLIT SCREEN</div>
                <ContentCard
                  setRefreshData={setRefreshData}
                  key={++index}
                  url={`${baseImg}/${data.url}`}
                  type={data.type}
                  name={data.name}
                  checked={data?.checked}
                  onCheckedSingleContent={onCheckedSingleContent}
                  onUncheckedSingleContent={onUncheckedSingleContent}
                  contentObject={data}
                  onAddPlaylist={onAddPlaylist}
                  onPushScreen={onPushScreen}
                  onDelete={onDelete}
                  onMove={onMove}
                  onPreview={onPreview}
                  createdAt={data.createdAt}
                  handleSeeSplitScreen={handleSeeSplitScreen}
                />
              );
            }
            else{
              if (data?.isActive) {
                return (
                  <ContentCard
                    key={++index}
                    url={`${baseImg}/${data.url}`}
                    type={data.type}
                    name={data.name}
                    checked={data?.checked}
                    onCheckedSingleContent={onCheckedSingleContent}
                    onUncheckedSingleContent={onUncheckedSingleContent}
                    contentObject={data}
                    onAddPlaylist={onAddPlaylist}
                    onPushScreen={onPushScreen}
                    onDelete={onDelete}
                    onMove={onMove}
                    onPreview={onPreview}
                    createdAt={data.createdAt}
                    handleTwitchPreview={handleTwitchPreview}
                    handleSeeTwitchDialog={handleSeeTwitchDialog}
                    handleSeeYoutubeDialog={handleSeeYoutubeDialog}
                  />
                );
              }
            }

            })
          : contentToView.map((data, index) => {
            if (data?.type == "split_screen" && data?.name && data?.createdAt && data?.orientation){
              return (
                <ListViewFile
                  setRefreshData={setRefreshData}
                  key={++index}
                  url={``}
                  type={data.type}
                  name={data.name || "undefined"}
                  checked={data?.checked}
                  onCheckedSingleContent={onCheckedSingleContent}
                  onUncheckedSingleContent={onUncheckedSingleContent}
                  contentObject={data}
                  onAddPlaylist={onAddPlaylist}
                  onPushScreen={onPushScreen}
                  onDelete={onDelete}
                  onMove={onMove}
                  onPreview={onPreview}
                  createdAt={data.createdAt}
                  handleSeeSplitScreen={handleSeeSplitScreen}
                />
              );
            }
            else{
              if (data?.isActive) {
                return (
                  <ListViewFile
                    key={++index}
                    url={`${baseImg}/${data.url}`}
                    type={data.type}
                    name={data.name}
                    checked={data?.checked}
                    onCheckedSingleContent={onCheckedSingleContent}
                    onUncheckedSingleContent={onUncheckedSingleContent}
                    contentObject={data}
                    onAddPlaylist={onAddPlaylist}
                    onPushScreen={onPushScreen}
                    onDelete={onDelete}
                    onMove={onMove}
                    onPreview={onPreview}
                    createdAt={data.createdAt}
                    handleTwitchPreview={handleTwitchPreview}
                    handleSeeTwitchDialog={handleSeeTwitchDialog}
                    handleSeeYoutubeDialog={handleSeeYoutubeDialog}
                  />
                );
              }
            }
            })}
      </Box>
      <Divider />

      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={content.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("views.all.rowsQty")}
      />
    </>
  );
}

export default All;
